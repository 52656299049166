var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid-2 flex-center" }, [
    _c("section", { staticClass: "upload--container" }, [
      _c("h2", { staticClass: "title" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.$t("uploadTitle")) + "\n            "
        ),
        _vm.maxSizeAllowed > 0
          ? _c(
              "span",
              { staticClass: "upload--limit" },
              [
                _c("i18n", { attrs: { path: "uploadLimit" } }, [
                  _c("span", { attrs: { place: "limit" } }, [
                    _vm._v(_vm._s(_vm.maxSizeAllowed / 1073741824))
                  ])
                ])
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "upload--limit retention" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("fileRetentionLimit")) +
              "\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "upload--widget-container", class: _vm.status },
        [
          _c("dx-file-uploader", {
            ref: "fileUploader",
            attrs: {
              name: "formFile",
              accept: "*",
              "upload-mode": _vm.uploadMode,
              "upload-url": _vm.fileUploaderUrl,
              multiple: true,
              "max-file-size": _vm.maxSizeAllowed,
              "chunk-size": _vm.getMaxChunkSize,
              "upload-chunk": _vm.uploadchunk
            },
            on: {
              uploaded: _vm.uploaded,
              "upload-aborted": _vm.uploadAborted,
              "value-changed": _vm.valueChanged,
              "upload-error": _vm.uploadError,
              "upload-started": _vm.uploadStart
            }
          }),
          _vm._v(" "),
          _vm.uploadAsError
            ? _c(
                "p",
                {
                  staticClass: "badge danger",
                  attrs: { role: "status", "aria-live": "polite" }
                },
                [_vm._v(_vm._s(_vm.$t("uploadErrorMesg")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.maxSizeAllowedError
            ? _c(
                "p",
                {
                  staticClass: "badge danger",
                  attrs: { role: "status", "aria-live": "polite" }
                },
                [
                  _c("i18n", { attrs: { path: "maxSizeAllowedErrorMesg" } }, [
                    _c("span", { attrs: { place: "limit" } }, [
                      _vm._v(_vm._s(_vm.maxSizeAllowed / 1073741824))
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showReset
            ? _c(
                "button",
                {
                  staticClass: "button reset--button",
                  on: { click: _vm.resetUpload }
                },
                [_vm._v("Effectuer un nouvel envoi")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.fileUrl
        ? _c("div", { staticClass: "upload--result" }, [
            _vm.uploadAsError
              ? _c(
                  "p",
                  {
                    staticClass: "badge danger",
                    attrs: { role: "status", "aria-live": "polite" }
                  },
                  [_vm._v(_vm._s(_vm.$t("uploadErrorMesg")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.uploadSuccess
              ? _c(
                  "p",
                  {
                    staticClass: "badge success",
                    attrs: { role: "status", "aria-live": "polite" }
                  },
                  [_vm._v(_vm._s(_vm.$t("uploadSucessMesg")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("label", { attrs: { for: "fileUrl" } }, [
              _vm._v(_vm._s(_vm.$t("uploadLabelInstruction")))
            ]),
            _vm._v(" "),
            _c("textarea", {
              staticClass: "upload--url",
              attrs: { id: "fileUrl", type: "text", readonly: "" },
              domProps: { value: _vm.fileUrl },
              on: {
                click: function($event) {
                  return _vm.copy("fileUrl", "link")
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "copy--container" }, [
              _c(
                "button",
                {
                  staticClass: "copy--button button",
                  attrs: { id: "copyLink" },
                  on: {
                    click: function($event) {
                      return _vm.copy("fileUrl", "link")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("copyLinkBtn")))]
              ),
              _vm._v(" "),
              _vm.linkWarning
                ? _c("i", { staticClass: "badge warning anim--up" }, [
                    _vm._v(_vm._s(_vm.$t("copyLinkWarning")))
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("label", { attrs: { for: "fileCode" } }, [
              _vm._v(_vm._s(_vm.$t("uploadCodeInstruction")))
            ]),
            _vm._v(" "),
            _c("input", {
              staticClass: "upload--code",
              attrs: { id: "fileCode", type: "text", readonly: "" },
              domProps: { value: _vm.fileCode },
              on: {
                click: function($event) {
                  return _vm.copy("fileCode", "code")
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "copy--container" }, [
              _c(
                "button",
                {
                  staticClass: "copy--button button",
                  attrs: { id: "copyLink" },
                  on: {
                    click: function($event) {
                      return _vm.copy("fileCode", "code")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("copyCodeBtn")))]
              ),
              _vm._v(" "),
              _vm.codeWarning
                ? _c("i", { staticClass: "badge warning anim--up" }, [
                    _vm._v(_vm._s(_vm.$t("copyCodeWarning")))
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "download--container" }, [
      _c("h2", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("downloadTitle")))
      ]),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "dowload--label", attrs: { for: "download--code" } },
        [_vm._v(_vm._s(_vm.$t("downloadInstruction")))]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.getCode,
            expression: "getCode"
          }
        ],
        staticClass: "download--code",
        attrs: { id: "download--code", type: "text" },
        domProps: { value: _vm.getCode },
        on: {
          keyup: function($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 13) {
              return null
            }
            return _vm.getFile($event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.getCode = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _vm.showError
        ? _c(
            "p",
            {
              staticClass: "badge danger",
              attrs: { role: "status", "aria-live": "polite" }
            },
            [_vm._v(_vm._s(_vm.$t("downloadMesg")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDownloadLink
        ? _c(
            "a",
            {
              staticClass: "button sr-only",
              attrs: { id: "click", href: _vm.getUrl, download: "" }
            },
            [_vm._v(_vm._s(_vm.$t("downloadBtn")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.getCode
        ? _c(
            "button",
            {
              staticClass: "download--button button",
              class: _vm.btnDownloadStatus,
              attrs: { disabled: _vm.btnDownloadStatusDisabled },
              on: { click: _vm.getFile }
            },
            [_vm._v(_vm._s(_vm.$t("downloadBtn")))]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }