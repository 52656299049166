import { eventBus } from '@/index';

export const LoadingMethods = {
    methods: {
        showLoadingPanel(customMessage) {
            eventBus.$emit('showing-load-panel', true, customMessage);
        },
        hideLoadingPanel() {
            eventBus.$emit('showing-load-panel', false);
        }
    }
};

export const LoadingUpdated = {
    updated() {
        eventBus.$emit('showing-load-panel', false);
    }
};